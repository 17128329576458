"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EIP712HubFacet__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
const _bytecode = "0x608080604052346100165761019f908161001c8239f35b600080fdfe608080604052600436101561001357600080fd5b600090813560e01c633644e5151461002a57600080fd5b346101335781600319360112610133576020816100536829a2a2a5a92fa42aa160b91b93610137565b600981520152603160f81b602060405161006c81610137565b600181520152604051602081017f8b73c3c69bb8fe3d512ecc4cf759cc79239f7b179b0ffacaa9a75d522b39400f81527fc20b67bcf9b157fdd147814aa88420db3cc88175df8abf953e782d1d53d8a2d660408301527fc89efdaa54c0f20c7adf612882df0950f5a951637e0307cdcb4c672f298b8bc660608301524660808301523060a083015260a0825260c082019180831067ffffffffffffffff84111761011f5760209350826040525190208152f35b634e487b7160e01b84526041600452602484fd5b5080fd5b6040810190811067ffffffffffffffff82111761015357604052565b634e487b7160e01b600052604160045260246000fdfea2646970667358221220719487692587431ca6a6abf094172ffe2c60e897b7dad09c7e1b009812f688e264736f6c63430008140033";
const isSuperArgs = (xs) => xs.length > 1;
class EIP712HubFacet__factory extends ethers_1.ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.EIP712HubFacet__factory = EIP712HubFacet__factory;
EIP712HubFacet__factory.bytecode = _bytecode;
EIP712HubFacet__factory.abi = _abi;
