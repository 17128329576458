"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EIP712ProjectFacet__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
const _bytecode = "0x60808060405234610016576101a3908161001c8239f35b600080fdfe608080604052600436101561001357600080fd5b600090813560e01c633644e5151461002a57600080fd5b346101375781600319360112610137576020816100576c14d15152d497d41493d29150d5609a1b9361013b565b600d81520152603160f81b60206040516100708161013b565b600181520152604051602081017f8b73c3c69bb8fe3d512ecc4cf759cc79239f7b179b0ffacaa9a75d522b39400f81527f2a369a7e82510cab57dbf4ae5d016d59fda30c6b121828dcc40e00a03b40398460408301527fc89efdaa54c0f20c7adf612882df0950f5a951637e0307cdcb4c672f298b8bc660608301524660808301523060a083015260a0825260c082019180831067ffffffffffffffff8411176101235760209350826040525190208152f35b634e487b7160e01b84526041600452602484fd5b5080fd5b6040810190811067ffffffffffffffff82111761015757604052565b634e487b7160e01b600052604160045260246000fdfea2646970667358221220f4aaf7d2ce67514a6c047d21f61e84d0588d7cf5b76756b76a505e2e75f73b6064736f6c63430008140033";
const isSuperArgs = (xs) => xs.length > 1;
class EIP712ProjectFacet__factory extends ethers_1.ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.EIP712ProjectFacet__factory = EIP712ProjectFacet__factory;
EIP712ProjectFacet__factory.bytecode = _bytecode;
EIP712ProjectFacet__factory.abi = _abi;
