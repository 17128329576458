"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeekrHubInitializerFacet__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        name: "NotPermittedError",
        type: "error",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "globals",
                type: "address",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
const _bytecode = "0x608080604052346100155760d4908161001b8239f35b600080fdfe6080806040526004361015601257600080fd5b600090813560e01c63c4d66de814602857600080fd5b34609a576020366003190112609a576004356001600160a01b03818116918290036096577f63b88da776e0a0275daaf5e043cf8fa042e504a7be7941afc0e9036ef13668c1928354918216608757506001600160a01b03191617905580f35b6346d4f7d360e01b8152600490fd5b8380fd5b5080fdfea26469706673582212203d1f3b36760358754262db86a70c717bfeac2aa07740ecb0921d93276cd61d2664736f6c63430008140033";
const isSuperArgs = (xs) => xs.length > 1;
class SeekrHubInitializerFacet__factory extends ethers_1.ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.SeekrHubInitializerFacet__factory = SeekrHubInitializerFacet__factory;
SeekrHubInitializerFacet__factory.bytecode = _bytecode;
SeekrHubInitializerFacet__factory.abi = _abi;
