"use strict";
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ISeekrEventBusFacet__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "companyAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "info",
                type: "string[]",
            },
        ],
        name: "CompanyChangedEvent",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "jobTokenId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "projectAddress",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "companyAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "tokenOwner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "info",
                type: "string[]",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "attributes",
                type: "string[]",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "skills",
                type: "string[]",
            },
        ],
        name: "JobCreatedEvent",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "jobTokenId",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "projectAddress",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "companyAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "additionalSkills",
                type: "string[]",
            },
        ],
        name: "JobFinalizedEvent",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "projectAddress",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "companyAddress",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "jobTokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address[]",
                name: "addressArgs",
                type: "address[]",
            },
            {
                indexed: false,
                internalType: "uint256[]",
                name: "numericArgs",
                type: "uint256[]",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "info",
                type: "string[]",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "attributes",
                type: "string[]",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "skills",
                type: "string[]",
            },
        ],
        name: "JobUpsertedEvent",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "projectAddress",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "companyAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "string[]",
                name: "info",
                type: "string[]",
            },
        ],
        name: "ProjectChangedEvent",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "string[]",
                name: "companyInfo",
                type: "string[]",
            },
        ],
        name: "emitCompanyChangedEvent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "jobTokenId",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "companyAddress",
                type: "address",
            },
            {
                internalType: "string[]",
                name: "additionalSkills",
                type: "string[]",
            },
        ],
        name: "emitJobFinalizedEvent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "addressParams",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "numericParams",
                type: "uint256[]",
            },
            {
                internalType: "string[]",
                name: "jobInfo",
                type: "string[]",
            },
            {
                internalType: "string[]",
                name: "attributes",
                type: "string[]",
            },
            {
                internalType: "string[]",
                name: "skills",
                type: "string[]",
            },
        ],
        name: "emitJobUpsertedEvent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "companyAddress",
                type: "address",
            },
            {
                internalType: "string[]",
                name: "projectInfo",
                type: "string[]",
            },
        ],
        name: "emitProjectChangedEvent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
class ISeekrEventBusFacet__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.ISeekrEventBusFacet__factory = ISeekrEventBusFacet__factory;
ISeekrEventBusFacet__factory.abi = _abi;
