"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EIP712CompanyFacet__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
const _bytecode = "0x60808060405234610016576101a3908161001c8239f35b600080fdfe608080604052600436101561001357600080fd5b600090813560e01c633644e5151461002a57600080fd5b346101375781600319360112610137576020816100576c5345454b525f434f4d50414e5960981b9361013b565b600d81520152603160f81b60206040516100708161013b565b600181520152604051602081017f8b73c3c69bb8fe3d512ecc4cf759cc79239f7b179b0ffacaa9a75d522b39400f81527f89a6e9349ad432ba99abc849b026b93f45cb3dd874ca5c990a92ae2cee9fe80b60408301527fc89efdaa54c0f20c7adf612882df0950f5a951637e0307cdcb4c672f298b8bc660608301524660808301523060a083015260a0825260c082019180831067ffffffffffffffff8411176101235760209350826040525190208152f35b634e487b7160e01b84526041600452602484fd5b5080fd5b6040810190811067ffffffffffffffff82111761015757604052565b634e487b7160e01b600052604160045260246000fdfea26469706673582212209bad63f428bfc609d0292573f93de768b448f7e3864ce210b98b52cce9b7eb8064736f6c63430008140033";
const isSuperArgs = (xs) => xs.length > 1;
class EIP712CompanyFacet__factory extends ethers_1.ContractFactory {
    constructor(...args) {
        if (isSuperArgs(args)) {
            super(...args);
        }
        else {
            super(_abi, _bytecode, args[0]);
        }
    }
    deploy(overrides) {
        return super.deploy(overrides || {});
    }
    getDeployTransaction(overrides) {
        return super.getDeployTransaction(overrides || {});
    }
    attach(address) {
        return super.attach(address);
    }
    connect(signer) {
        return super.connect(signer);
    }
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.EIP712CompanyFacet__factory = EIP712CompanyFacet__factory;
EIP712CompanyFacet__factory.bytecode = _bytecode;
EIP712CompanyFacet__factory.abi = _abi;
