"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IAccessControlUpgradeable__factory = exports.IAccessControlEnumerableUpgradeable__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var IAccessControlEnumerableUpgradeable__factory_1 = require("./IAccessControlEnumerableUpgradeable__factory");
Object.defineProperty(exports, "IAccessControlEnumerableUpgradeable__factory", { enumerable: true, get: function () { return IAccessControlEnumerableUpgradeable__factory_1.IAccessControlEnumerableUpgradeable__factory; } });
var IAccessControlUpgradeable__factory_1 = require("./IAccessControlUpgradeable__factory");
Object.defineProperty(exports, "IAccessControlUpgradeable__factory", { enumerable: true, get: function () { return IAccessControlUpgradeable__factory_1.IAccessControlUpgradeable__factory; } });
