"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDiamondLoupe__factory = exports.IDiamondCut__factory = void 0;
/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
var IDiamondCut__factory_1 = require("./IDiamondCut__factory");
Object.defineProperty(exports, "IDiamondCut__factory", { enumerable: true, get: function () { return IDiamondCut__factory_1.IDiamondCut__factory; } });
var IDiamondLoupe__factory_1 = require("./IDiamondLoupe__factory");
Object.defineProperty(exports, "IDiamondLoupe__factory", { enumerable: true, get: function () { return IDiamondLoupe__factory_1.IDiamondLoupe__factory; } });
